import React from 'react';
import { useHistory } from 'react-router-dom';
import './css/ValentinesYes.css';

const ValentinesYes = () => {
  const history = useHistory();

  const handleBackClick = () => {
    history.push('/valentines');
  };

  return (
    <div className="valentines-common-container valentines-yes-container">
      <button onClick={handleBackClick} className="back-button">
        Back
      </button>
      <h2>Good Answer</h2>

      <div className="sakura-background"></div>

      <img
        src="https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExaTd5cGY3YjNpb3JydnI0cGU4c3JxNmQzejVvNWJ6Y2cyY3F1azFqeSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/GeimqsH0TLDt4tScGw/giphy.gif"
        alt="Happy Cat Gif"
      />
      <p>LET'S GOOOOOO! Get your sexy ass ready for Friday Feburary 23rd :)</p>
    </div>
  );
};

export default ValentinesYes;