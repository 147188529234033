import './css/ValentinesPw.css';
import React, { useState } from 'react';
import { useHistory } from "react-router-dom";

const ValentinesPw = ({ setValentinesAuth }) => {
  const [password, setPassword] = useState('');
  const history = useHistory();
  
  const handlePasswordSubmit = () => {
    const correctPassword = 'alyssavalentine';

    if (password === correctPassword) {
      setValentinesAuth(true);
      history.push("/Valentines");
    } else {
      alert('WRRROONNNGGGGGG');
    }
  };

  return (
    <div className="valentines-pw-container">
      <h2>To My Valentine</h2>
      <div className="password-input-container">
        <input
          type="password"
          placeholder="🤔 Password? 🤔"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handlePasswordSubmit();
            }
          }}
          className="password-input"
        />
        <button onClick={handlePasswordSubmit} className="submit-button">
          Submit
        </button>
      </div>
    </div>
  );
};

export default ValentinesPw;