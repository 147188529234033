import React, { useState } from 'react';
import './css/ValentinesPlansPwPopup.css';

const ValentinesPlansPwPopup = ({ onPasswordSubmit, onClose }) => {
    const [password, setPassword] = useState('');

    const handleSubmit = () => {
        onPasswordSubmit(password);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    };

  return (
    <div className="valentines-plans-pw-popup-container">
      <h2 className="valentines-plans-pw-popup-title">Another Password?? If u don't know it u a nosy mf</h2>
      <input
        type="password"
        placeholder="🤔 Another Password 🤔"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        onKeyPress={handleKeyPress}
        className="valentines-plans-pw-popup-input"
      />
      <div className="valentines-plans-pw-popup-buttons">
        <button className="valentines-plans-pw-popup-close" onClick={onClose}>
          Close
        </button>
        <button className="valentines-plans-pw-popup-submit" onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default ValentinesPlansPwPopup;