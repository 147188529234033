import JSZip from 'jszip';

export const extractJsonFromZip = async (byteArray) => {
    const jsonData = {
        blockedAccounts: null,
        closeFriends: null,
        followers1: null,
        following: null,
        recentFollowRequests: null,
        recentlyUnfollowedAccounts: null,
        removedSuggestions: null
    };

    try {
        const zip = await JSZip.loadAsync(byteArray);

        for (const fileName of Object.keys(zip.files)) {
        console.log(`Processing file: ${fileName}`); // Log each file name

        // Extract file name from path
        const fileParts = fileName.split('/');
        const actualFileName = fileParts[fileParts.length - 1];

        const file = zip.file(fileName);
        if (file && actualFileName.endsWith('.json')) {
            const fileContent = await file.async('string');
            const parsedData = JSON.parse(fileContent);

            switch (actualFileName) {
            case 'blocked_accounts.json':
                jsonData.blockedAccounts = parsedData;
                break;
            case 'close_friends.json':
                jsonData.closeFriends = parsedData;
                break;
            case 'followers_1.json':
                jsonData.followers1 = parsedData;
                break;
            case 'following.json':
                jsonData.following = parsedData;
                break;
            case 'recent_follow_requests.json':
                jsonData.recentFollowRequests = parsedData;
                break;
            case 'recently_unfollowed_accounts.json':
                jsonData.recentlyUnfollowedAccounts = parsedData;
                break;
            case 'removed_suggestions.json':
                jsonData.removedSuggestions = parsedData;
                break;
            default:
                console.log(`File ${actualFileName} not handled.`);
                break;
            }

            console.log(`Extracted data for ${actualFileName}:`, parsedData); // Log extracted JSON data
        }
        }
    } catch (error) {
        console.error('Error extracting ZIP file:', error); // Log any errors
    }

    return jsonData;
};

export const findUnfollowers = (followers, followings) => {
    if (!followers || !followings) {
        return [];
    }

    // Create a set of URLs from followers1.json for quick lookup
    const followers1Urls = new Set(
        followers.map(follower => follower.string_list_data.map(data => data.href)).flat()
    );

    // Find items in following.json that are not in followers1.json
    const uniqueFollowings = followings.relationships_following.filter(following =>
        following.string_list_data.some(data => !followers1Urls.has(data.href))
    );

    return uniqueFollowings;
}