import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './css/ValentinesNo.css';

const ValentinesNo = () => {
  const history = useHistory();
  const [messageIndex, setMessageIndex] = useState(0);
  const [showYesText, setShowYesText] = useState(false);
  const [clickCount, setClickCount] = useState(0);
  const [enoughClicking, setEnoughClicking] = useState(false);

  const messages = [
    "I'm so sad you said no. :(",
    "Why did you say no? :(",
    "I'll be okay, I guess. :(",
  ];

  const gifs = [
    "https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExY3V6dXlrYzNkZGZ6NTd5bHRiYnhxNTF6dmF1bDl2OGM1MzVvd3pkNSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/DYckNX9XyU9zdynEYm/giphy.gif",
    "https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExcW9mMG5pa25lYnNoOTV0bGxkNWg0ZWR1bXFlZnB0a3p3Nnk1YnAyZCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/GwskZm1jXg8cDvuZJ6/giphy.gif",
    "https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExc2R0bzZtcHl3NjlpZXQzN3doZXBnMzR5dzN4aHBjMnJnYmRqMTJkaCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/RvO4oyf6Hhvek3n80W/giphy.gif"
  ];

  const handleBackClick = () => {
    history.push('/valentines');
  };

  const handleYesClick = () => {
    setMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
    setShowYesText(true);
    setClickCount(clickCount + 1);
    if (clickCount > 5) {
      setEnoughClicking(true);
    }
  };

  const renderPreEnoughClicking = () => (
    <>
      <h2>No????????</h2>

      {gifs[messageIndex] && (
        <img
          src={gifs[messageIndex]}
          alt='Sad Cat Gif'
          className='sad-cat-gif'
        />
      )}

      <p style={{ margin: '20px 0' }}>Are you sure? :(</p>
      {messages[messageIndex] && (
        <p style={{ display: showYesText ? 'block' : 'none' }}>{messages[messageIndex]}</p>
      )}
      <button onClick={handleBackClick} className="back-button">
        Back
      </button>
      <button onClick={handleYesClick} className="yes-button">
        Yes
      </button>
    </>
  );

  const renderEnoughClicking = () => (
    <>
      <h2>ok that is enough clicking "yes" you know you don't mean it smh</h2>

      <button onClick={handleBackClick} className="back-button">
        Back
      </button>
    </>
  );

  return (
    <div className="valentines-common-container valentines-no-container">
      {enoughClicking
        ? renderEnoughClicking()
        : renderPreEnoughClicking()}
    </div>
  );
};

export default ValentinesNo;