import React, { Component } from 'react';
import axios from 'axios';
import './css/tic-tac-toe.css';
import Status from "./Status";
import Pool from "../../UserPool";
import Modal from "./modal";

class tictactoe extends Component {
    constructor(){
        super();
        this.state = {
            player:"X",
            board: ["", "", "", "", "", "", "", "", ""],
            username: "",
            wins: 0,
            maxScore: 0,
            allUsers: null
        }

        this.handlePlay = this.handlePlay.bind(this);
        this.robotPlay = this.robotPlay.bind(this);
        this.weHaveAWinner = this.weHaveAWinner.bind(this);
        this.endGame = this.endGame.bind(this);
        this.updateScore = this.updateScore.bind(this);
    }


    async componentDidMount(){
        let user = Pool.getCurrentUser();
        if (user) {
            let userResponse;
            await axios.get(`https://a5ka4xtodb.execute-api.us-west-1.amazonaws.com/dev/tic-tac-toe/user?username=${user.getUsername()}`)
            .then(function(res) {
                //console.log("AXIOS GET SUCCESS: ", res);
                userResponse = res;
            })
            .catch(function(err) {
                //console.error("AXIOS GET ERROR: ", err);
            });

            let userWins = userResponse.data.wins;
            this.setState({ 
                username: user.getUsername(),
                wins: userWins 
            });
        }

        const allUsersRes = await axios.get("https://a5ka4xtodb.execute-api.us-west-1.amazonaws.com/dev/tic-tac-toe/users");
        const allUsersData = allUsersRes.data.users;
        //console.log("ALLUSERS: ", allUsersData);
        let [users, maxScore] = this.getMaxScore(allUsersData);
        this.setState({
            maxScore: maxScore,
            usersWithMaxScore: users,
            allUsers: allUsersData
        });
    }

    getMaxScore(allUsers) {
        /*
            Get the max score and the username(s) who have this max score.
        */
        var usernames = [];
        var maxWins = 0;

        for(var i = 0; i < allUsers.length; i++){
            let checkUsername = allUsers[i].username;
            let checkWins = allUsers[i].wins;

            if(checkWins === maxWins) {
                usernames.push(checkUsername);
            } 
            else if (checkWins > maxWins) {
                usernames = [checkUsername];
                maxWins = checkWins;
            }
        }
        
        return [usernames, maxWins];
    }


    handlePlay(index){
        let current_player = this.state.player;
        let current_board = this.state.board;

        // If empty spot, assign board index value to current player then switch player
        // Else do nothing (square already has a value)
        if (current_board[index] === "" || current_board[index] === null) {
            current_board[index] = current_player;
            current_player = current_player === "X" ? "O" : "X";
        } else {
            return;
        }

        this.setState({
            player: current_player,
            board: current_board
        });

        const sleep = (milliseconds) => {
            return new Promise(resolve => setTimeout(resolve, milliseconds));
        }
        
        // If no winner and the board isn't full, let the robot play
        if (this.checkWinner(this.state.board) === "X") {
            sleep(250).then(() => {this.weHaveAWinner("X")});
        } else if (this.checkFull(this.state.board)){
            sleep(250).then(() => alert("Tie Game"));
            this.updateScore("tie");
            sleep(250).then(() => {this.endGame()});
        } else {
            sleep(100).then(() => {this.robotPlay()});
        }
    }


    async robotPlay(){
        let robot_player = this.state.player;
        let robot_board = this.state.board;

        // We need to game to be winnable, pick random number between 1-8 and if 4 then play random open move
        let randomNumber = Math.floor(Math.random() * 9);
        if (randomNumber === 4) {
            var played_move = false;
            while (!played_move) {
                let index = Math.floor(Math.random() * 9);
    
                if (robot_board[index] === "" || robot_board[index] === null) {
                    robot_board[index] = robot_player;
                    robot_player = robot_player === "X" ? "O" : "X";
                    played_move = true;
                }
            }
        } else { 
            const bestSquare = this.findBestSquare(this.state.board, this.state.player);
            if (bestSquare !== -1) {
                robot_board[bestSquare] = robot_player;
                robot_player = robot_player === "X" ? "O" : "X";
            }
        }

        this.setState({
            player: robot_player,
            board: robot_board
        });

        const sleep = (milliseconds) => {
            return new Promise(resolve => setTimeout(resolve, milliseconds));
        }

        // Check if winner or if board is full
        if (this.checkWinner(this.state.board) === "O") {
            sleep(250).then(() => {this.weHaveAWinner("O")});
        } else if (this.checkFull(this.state.board)) {
            sleep(250).then(() => alert("Tie Game"));
            this.updateScore("tie");
            sleep(250).then(() => {this.endGame()});
        }
    }


    findBestSquare(squares, player) {
        /*
            Copied (and adjusted) code from Wilson Louie
                - https://javascript.plainenglish.io/lets-add-an-unbeatable-ai-to-react-tic-tac-toe-c57392579381
        */

        // 'player' is the maximizing player
        // 'opponent' is the minimizing player
        const opponent = player === 'X' ? 'O' : 'X';
        
        const minimax = (squares, isMax) => {
            const winner = this.checkWinner(squares);
            //console.log(winner);
            
            // If player wins, score is +1
            if (winner === player) return { square: -1, score: 1 };
            
            // If opponent wins, score is -1
            if (winner === opponent) return { square: -1, score: -1 };
            
            // If Tie, score is 0
            if (this.checkFull(squares)) return { square: -1, score: 0 };
            
            // Initialize 'best'. If isMax, we want to maximize score, and minimize otherwise.
            const best = { square: -1, score: isMax ? -1000 : 1000 };
            
            // Loop through every square on the board
            for (let i = 0; i < squares.length; i++) {
                // If square is already filled, it's not a valid move so skip it
                if (squares[i]) {
                    continue;
                }
                
                // If square is unfilled, then it's a valid move. Play the square.
                squares[i] = isMax ? player : opponent;
                // Simulate the game until the end game and get the score,
                // by recursively calling minimax.
                const score = minimax(squares, !isMax).score;
                // Undo the move
                squares[i] = null;
            
                if (isMax) {
                    // Maximizing player; track the largest score and move.
                    if (score > best.score) {
                    best.score = score;
                    best.square = i;
                    }
                } else {
                    // Minimizing opponent; track the smallest score and move.
                    if (score < best.score) {
                    best.score = score;
                    best.square = i;
                    }
                }
            }
            
            // The move that leads to the best score at end game.
            return best;
        };

        return minimax(squares, true).square;
    }


    checkWinner(squares){
        let winningConditions = [
            [0, 1, 2],
            [3, 4, 5],
            [6, 7, 8],
            [0, 3, 6],
            [1, 4, 7],
            [2, 5, 8],
            [0, 4, 8],
            [2, 4, 6]
        ];

        for (let i = 0; i < winningConditions.length; i++) {
            const [a, b, c] = winningConditions[i];
            if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
                return squares[a];
            }
        }

        return null;
    }


    checkFull(squares){
        for(let i = 0; i < squares.length; i++){
            if (squares[i] !== "X" && squares[i] !== "O") {
                return false;
            }
        }

        return true;
    }


    weHaveAWinner(player){
        let winner = player === "X" ? "User" : "Computer";
        alert(`The winner is: ${winner}`);
        this.updateScore(winner);
        this.endGame();
    }


    async updateScore(winner) {
        // TODO:  winRes prints undefined
        /*
            If the user wins - winCondition is True, else False.
        */  
        if(winner === "User") {
            console.log("Won Game");
            await this.patchUser("win");

            // Update the State's score, so the user sees the feedback quickly.
            // Upon reload the Wins should be aligned as the Component will mount again. 
            this.setState({ wins: this.state.wins + 1});
        } else if (winner === "Computer") {
            console.log("Lost Game");
            await this.patchUser("loss")
        } else {
            console.log("Tie Game");
            await this.patchUser("tie");
        }
    }

    async patchUser(userWon) {
        const url = `https://a5ka4xtodb.execute-api.us-west-1.amazonaws.com/dev/tic-tac-toe/user`;
        const data = {
            "username": this.state.username,
            "winCondition": userWon
        }
        const config = {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods':'GET,POST,DELETE,PATCH,OPTIONS',
        }

        return axios.patch(url, data, config).then(function(res) {
            //console.log("PATCH SUCCESS: ", res);
        })
        .catch(function(err) {
            //console.error("PATCH ERROR: ", err);
        });
    }


    endGame(){
        this.setState({ 
            player: "X",
            board: ["", "", "", "", "", "", "", "", ""]
        });
    }

    
    render() {
        // Check if there is a user currently logged in
        let currentUsername;
        if(Pool.getCurrentUser()){
            currentUsername = Pool.getCurrentUser().getUsername();
        }
        
        return (
            <div className="container">
                <h1>Tic Tac Toe</h1>

                <br/>
                <Modal users={this.state.allUsers}/>

                <br/><br/>
                <div className="play-area">
                    {this.state.board.map((square, index) => {
                        return(<div onClick={(e) => this.handlePlay(index, e)} className="block" key={index} id={ "block_" + String(index) }>{square}</div>)
                    })}
                </div>
                <br/><br/>

                <p>Total Wins: {this.state.wins}</p>
                {currentUsername ? <p>Welcome, {currentUsername}</p> : null}
                <br/>

                <Status />
            </div>
        );
    }

}

export default tictactoe;