import { CognitoUserPool } from "amazon-cognito-identity-js";

const poolData = {
    //UserPoolId: "us-west-1_P3hB4ldFl",
    //ClientId: "2g55bbe4althg7pkhkirqrtiro"

    // Personal Webiste TTT
    UserPoolId: "us-west-1_MobyHN0Mh",
    ClientId: "1ior4mio6ac45svkuhcq401luq"
}

export default new CognitoUserPool(poolData);