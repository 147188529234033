import './App.css';
import React, { useState } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Followers from "./Components/followers/followers.js";
import TicTacToe from "./Components/tic-tac-toe/tic-tac-toe";
import Login from "./Components/tic-tac-toe/login";
import CreateAccount from "./Components/tic-tac-toe/createAccount";
import { Account } from "./Components/tic-tac-toe/Account";
import PhotoName from './Components/photo_name';
import Valentines from './Components/Valentines/Valentines';
import ValentinesPw from './Components/Valentines/ValentinesPw';
import ValentinesNo from './Components/Valentines/ValentinesNo';
import ValentinesYes from './Components/Valentines/ValentinesYes';
import ValentinesPlans from './Components/Valentines/ValentinesPlans';


export default function App() {
  const [valentinesAuth, setValentinesAuth] = useState(false);
  const [valentinesPlansAuth, setValentinesPlansAuth] = useState(false);

  return (
    <div className="App-intro">
      <BrowserRouter>
        <Switch>

          <Route exact path="/"  component={PhotoName} />    

          {/* Valentines Routing */}
          <Route exact path="/ValentinesNo" component={ValentinesNo} />
          <Route exact path="/ValentinesYes" component={ValentinesYes} />
          <Route path="/ValentinesPw">
            <ValentinesPw setValentinesAuth={setValentinesAuth} />
          </Route>
          <Route path="/Valentines">
            {valentinesAuth 
              ? <Valentines setValentinesPlansAuth={setValentinesPlansAuth} /> 
              : <Redirect to="/ValentinesPw" />}
          </Route>
          <Route path="/ValentinesPlans">
            {valentinesAuth && valentinesPlansAuth ? <ValentinesPlans /> : <Redirect to="/ValentinesPw" />}
          </Route>

          {/* followers routing */}
          <Route exact path="/followers" component={Followers}/>

          {/* tic-tac-toe routing */}
          <Account>
            <Route exact path="/tic-tac-toe" component={TicTacToe} />
            <Route exact path="/tic-tac-toe/login" component={Login} />
            <Route exact path="/tic-tac-toe/create" component={CreateAccount} />
          </Account>
          <Redirect to="/" />
        
        </Switch>
      </BrowserRouter>
    </div>
  );
}