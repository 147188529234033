import React, { useContext, useState } from 'react';
import { AccountContext } from "./Account";
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Pool from "../../UserPool";
import "./css/login.css";
import loginBG from "../../Pictures/loginBG.jpg";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const { authenticate } = useContext(AccountContext);

    let history = useHistory();

    const onLogin = (event) => {
        event.preventDefault();
        
        authenticate(email, password)
            .then((data) => {
                console.log("Logged in!", data);
                alert("Successfully logged in");
                history.push('/tic-tac-toe');
            })
            .catch((err) => {
                console.error("Failed to login", err);
                alert("Incorrect email or password");
            });
    };


    const onCreate = () => {
       history.push('/tic-tac-toe/create');
    };

    const theForm = () => (
        <>
        <h1>Welcome!</h1>
        <form noValidate autoComplete="off" onSubmit={onLogin}>
            <br/><br/>
            <TextField
                required
                id="outlined-required"
                label="Email/Username"
                className="inputBox"
                variant="outlined"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
            />

            <br/><br/>
            <TextField
                required
                id="outlined-password-input-required"
                label="Password"
                type="password"
                autoComplete="current-password"
                className="inputBox"
                variant="outlined"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
            />
            <br/><br/>

            <div className="signInButton">
                <Button color="primary" type="submit">Sign In</Button>
            </div>

            <br/>
        </form>
        <Button color="secondary" onClick={onCreate}>Create Account</Button>
        </>
    )


    const loggedIn = () => (
        <>
        <h1>You are already logged in!</h1>
        <br/>
        <p>Return to the Tic Tac Toe home page to sign out.</p>
        </>
    );


    const checkSession = () => {
        let user = Pool.getCurrentUser();

        if (user) {
            return true;
        } else {
            return false;
        }
    }


    return(
        <div className="container" style={{ backgroundImage: `url(${ loginBG })` }}>
            {checkSession() ? loggedIn() : theForm()}
        </div>
    );
}


export default Login;