import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));


export default function SimpleModal(props) {
    const classes = useStyles();

    const modalStyle = {
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    }
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    let allUsers = props.users;
    let leaderboardUsernameData;
    let leaderboardScoreData;
    // Ensure that the GET request has completed, else render null
    if (allUsers === undefined || allUsers === null) {
        return null;
    } else {
        [leaderboardUsernameData, leaderboardScoreData] = getLeaderboard(allUsers);
    }

    function getLeaderboard(allUsers) {
        /*
            Get the max score and the username(s) who have this max score. Return arrays of these
        */
        var usernameBuilder = ["","","","",""];
        var scoreBuilder = [0,0,0,0,0];

        for(let i = 0; i < 10; i++) {
            let currentMaxUser = "";
            let currentMaxScore = 0;
            for(let j = 0; j < allUsers.length; j++) {
                if (allUsers[j].wins > currentMaxScore && usernameBuilder.indexOf(allUsers[j].username) === -1) {
                    currentMaxScore = allUsers[j].wins;
                    currentMaxUser = allUsers[j].username;
                }
            }
            usernameBuilder[i] = currentMaxUser;
            scoreBuilder[i] = currentMaxScore;
        }

        return [usernameBuilder, scoreBuilder];
    }

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <h2 id="simple-modal-title">Leaderboard</h2>

            <br />

            <p>1. {leaderboardUsernameData[0]} -- {leaderboardScoreData[0]}</p>
            <p>2. {leaderboardUsernameData[1]} -- {leaderboardScoreData[1]}</p>
            <p>3. {leaderboardUsernameData[2]} -- {leaderboardScoreData[2]}</p>
            <p>4. {leaderboardUsernameData[3]} -- {leaderboardScoreData[3]}</p>
            <p>5. {leaderboardUsernameData[4]} -- {leaderboardScoreData[4]}</p>
            <p>6. {leaderboardUsernameData[5]} -- {leaderboardScoreData[5]}</p>
            <p>7. {leaderboardUsernameData[6]} -- {leaderboardScoreData[6]}</p>
            <p>8. {leaderboardUsernameData[7]} -- {leaderboardScoreData[7]}</p>
            <p>9. {leaderboardUsernameData[8]} -- {leaderboardScoreData[8]}</p>
            <p>10. {leaderboardUsernameData[9]} -- {leaderboardScoreData[9]}</p>
        </div>
    );

    return (
        <div>
        <Button variant="contained" type="button" onClick={handleOpen}>
            Leaderboard
        </Button>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            {body}
        </Modal>
        </div>
    );
}