import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './css/Valentines.css';
import ValentinesPlansPwPopup from './ValentinesPlansPwPopup';

const Valentines = ({ setValentinesPlansAuth }) => {
  const history = useHistory();
  const [showPasswordPopup, setShowPasswordPopup] = useState(false);

  const handleYesClick = () => {
    history.push('/ValentinesYes');
  };

  const handleNoClick = () => {
    history.push('/ValentinesNo');
  };

  const handleHomeClick = () => {
    setShowPasswordPopup(false);
    history.push('/Valentines');
  };

  const handlePlansClick = () => {
    setShowPasswordPopup(true);
  };

  const handlePasswordSubmit = (password) => {
    if (password === 'cameronsplans123') {
      setValentinesPlansAuth(true);
      history.push('/ValentinesPlans');
    } else {
      alert("Come on bruh why u trying, we both know u don't know it");
    }
  };

  const closePasswordPopup = () => {
    setShowPasswordPopup(false);
  };

  const renderValentinesHome = () => (
    <>
      <h2>Will you be my valentine?</h2>
      <div className="image-container">
        <img
          src="https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExbDJkcDZ3ZnhmajMyMzRwM3BpZ2Y3d2U5c2oxejJpZzAwdWc4dzVmYyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/MziKDo6gO7x8A/giphy.gif"
          alt="Valentine"
        />
        <h3>You know there is only one right answer 🥱🥱</h3>
      </div>
      <div className="button-container">
        <button className="yes-button" onClick={handleYesClick}>
          Yes
        </button>
        <button className="no-button" onClick={handleNoClick}>
          No
        </button>
      </div>
    </>
  );

  const renderValentinesPlansPwPopup = () => (
    <>
      {showPasswordPopup && (
        <ValentinesPlansPwPopup
          onPasswordSubmit={handlePasswordSubmit}
          onClose={closePasswordPopup}
        />
      )}
    </>
  );

  return (
    <>
      <div className="valentines-container">
        <nav>
          <ul>
            <li>
              <a onClick={handleHomeClick}>
                Home
              </a>
            </li>
            <li>
              <a onClick={handlePlansClick} className="plans-button">
                02/23 Plans
              </a>
            </li>
          </ul>
        </nav>

        {showPasswordPopup
          ? renderValentinesPlansPwPopup()
          : renderValentinesHome()}
      </div>
    </>
  );
};

export default Valentines;