import React, { useState } from 'react';
import './css/followers.css';
import { extractJsonFromZip, findUnfollowers } from './followersService';

const Followers = () => {
    const [jsonData, setJsonData] = useState({});
    const [isUploaded, setIsUploaded] = useState(false);
    const [nonFollowers, setNonFollowers] = useState([]);

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (file && file.type === 'application/zip') {
            const reader = new FileReader();
            reader.onload = async (e) => {
                const arrayBuffer = e.target.result;
                const byteArray = new Uint8Array(arrayBuffer);
                const extractedData = await extractJsonFromZip(byteArray);
                setNonFollowers(findUnfollowers(extractedData.followers1, extractedData.following));
                setJsonData(extractedData);
                setIsUploaded(true);
            };
            reader.readAsArrayBuffer(file);
        } else {
            alert('Please upload a zip file.');
        }
    };

    return (
        <div className="followers-container">
        {!isUploaded && (
            <div className="instructions">
                <h2>Instructions</h2>
                <ol>
                    <li>Go to your Instagram Profile</li>
                    <li>Click on the 3 Horizontal Lines on the top right corner</li>
                    <li>Select Accounts Center</li>
                    <li>Go to "Your information and permissions"</li>
                    <li>Select "Download your information"</li>
                    <li>Select "Download or transfer information"</li>
                    <li>Select "Some of your information"</li>
                    <li>Choose "Followers and following"</li>
                    <li>Change Format to be "JSON"</li>
                </ol>
            </div>
        )}
        <input type="file" accept=".zip" onChange={handleFileUpload} className="file-input" />
        {isUploaded && (
            <>
            <h3 className="file-title">Non Followers Found:</h3>
            {nonFollowers.length > 0 ? (
                <div className="scrollable-container">
                {nonFollowers.map((item, index) => (
                    <div key={index} className="json-row">
                    {item.string_list_data.map((data, i) => (
                        <div key={i} className="row-content">
                        <p className="username">{data.value}</p>
                        <a href={data.href} target="_blank" rel="noopener noreferrer" className="instagram-button">View Profile</a>
                        </div>
                    ))}
                    </div>
                ))}
                </div>
            ) : (
                <p>No non-followers found.</p>
            )}
            </>
        )}
        </div>
    );
};

export default Followers;
