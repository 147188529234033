import React from 'react';
import { useHistory } from 'react-router-dom';
import './css/ValentinesPlans.css';

const ValentinesPlans = () => {
    const history = useHistory();

    const handleBackClick = () => {
        history.push('/Valentines');
    };


  return (
    <div className="valentines-plans-container">
        <button className="back-button" onClick={handleBackClick}>
            Back
        </button>

      <div className="timeline">
        <div className="timeline-item">
          <div className="circle">1</div>
          <div className="plan">I pick up Alyssa and she gets pretty (which is easy af)</div>
        </div>

        <div className="timeline-item">
          <div className="line"></div>
        </div>

        <div className="timeline-item">
          <div className="circle">2</div>
          <div className="plan">Drive to SF</div>
        </div>

        <div className="timeline-item">
          <div className="line"></div>
        </div>

        <div className="timeline-item">
          <div className="circle">3</div>
          <div className="plan">ChottoMatte reservation @ 7:45 PM</div>
        </div>

        <div className="timeline-item">
          <div className="line"></div>
        </div>

        <div className="timeline-item">
          <div className="circle">4</div>
          <div className="plan">Get dessert or go to an aesthetic bar (I have some potentials)</div>
        </div>

        <div className="timeline-item">
          <div className="line"></div>
        </div>

        <div className="timeline-item">
          <div className="circle">5</div>
          <div className="plan">Drive back to SJ!</div>
        </div>
      </div>
    </div>
  );
};

export default ValentinesPlans;
