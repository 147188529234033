import React, { Component } from 'react';
import self_portrait from '../Pictures/Cameron.jpg';
import resume from '../Pictures/Lau_Cameron-Resume.pdf';
import './photo_name.css';
import BG_Photo from '../Pictures/aerial.jpg';

export default class PhotoName extends Component {
    render(){
        return (
            <div className="App" style={{ backgroundImage: `url(${ BG_Photo })` }}>
                <div className="centered">
                    <div className="console">
                        <div className="photo">
                            <img src={self_portrait} alt="Me" />
                        </div>

                        <br/>
                        
                        <div className="name">
                            <h1>Cameron Lau</h1>
                        </div>
                        
                        <br/>
                        
                        <div className="description">
                            <p><b>Software Engineer @ PayPal</b></p>
                        
                        </div>
                        
                        <br/>
                        
                        <div className="icons">
                            <a href="https://github.com/cameronlxu" target="_blank" rel="noreferrer"><h3>Github</h3></a><br/>
                            <a href="https://www.linkedin.com/in/cameronlau/" target="_blank" rel="noreferrer"><h3>LinkedIn</h3></a><br/>
                            <a href={resume} target="_blank" rel="noreferrer" ><h3>Resume</h3></a><br/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}